import React from 'react';
import { useAppContext } from '../../utils/AppContext';

const ProfileModal: React.FC = () => {
  const { userInfo } = useAppContext();
  return (
    <div>
      <h2>User Information</h2>
      <p>Email: {userInfo?.email}</p>
      <p>Auth Key: {userInfo?.auth_key}</p>
      <p>Request Count: {userInfo?.request_count}</p>
      <p>Credit: {userInfo?.credit}</p>
    </div>
  );
};

export default ProfileModal;