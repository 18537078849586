import React from 'react';
import Modal from './common/Modal';
import { useAppContext } from '../utils/AppContext';
import SignInForm from './modals/SignInForm';
import SignUpForm from './modals/SignUpForm';
import ProfileModal from './modals/ProfileModal';

const ModalContainer: React.FC = () => {
  const {isSignInModalOpen, setIsSignInModalOpen, isSignUpModalOpen, setIsSignUpModalOpen, isProfileModalOpen, setIsProfileModalOpen} = useAppContext();
  return (
    <>
      <Modal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)}>
        <SignInForm onSignIn={() => setIsSignInModalOpen(false)} />
      </Modal>

      <Modal isOpen={isSignUpModalOpen} onClose={() => setIsSignUpModalOpen(false)}>
        <SignUpForm onSignUp={() => setIsSignUpModalOpen(false)} />
      </Modal>

      <Modal isOpen={isProfileModalOpen} onClose={() => setIsProfileModalOpen(false)}>
        <ProfileModal />
      </Modal>
    </>
  );
};

export default ModalContainer;