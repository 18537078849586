import React, { useEffect } from 'react';
import ArrowIcon from '../icons/arrow';
import { useParallax, useParallaxController } from 'react-scroll-parallax';
import ArrowMobileIcon from '../icons/arrow-mobile';

const imagesCount = 136; // Total number of images you have
const headImgs: string[] = [];

for (let i = 0; i < imagesCount; i++) {
  const imageName = `Infini-3D.${i.toString().padStart(4, '0')}.png`;
  headImgs.push(`/images/pngs/${imageName}`);
}

interface IHeroSectionProps {
  title: string;
  subtitle: string;
  description: string;
}
const HeroSection: React.FC<IHeroSectionProps> = ({ title, subtitle, description }) => {
  const [currentHeadImage, setCurrentHeadImage] = React.useState(0);
  const currentImageIndexRef = React.useRef(0);
  const titleParallaxRef = useParallax({
  });
  const descriptionParallaxRef = useParallax({
  });
  const nicheParallaxRef = useParallax({});
  const parallaxController = useParallaxController();

  // const location = useLocation();
  useEffect(() => {
    if (parallaxController) {
      parallaxController.update();
    }
  }, [parallaxController]);

  const tensorParallaxRef = useParallax({});
  const arrowParallaxRef = useParallax({});
  const headParallaxRef = useParallax<HTMLDivElement>({
    speed: 10,
  });

  const divParallaxRef = useParallax<HTMLDivElement>({
    onProgressChange: (progress) => {
      const translateHeadY = -progress * 10; // Adjust the multiplier as needed for the desired effect

      const newIndex = Math.min(Math.floor((progress * 3) * 135), 135)
      if (headParallaxRef.ref.current) {

        if (newIndex !== currentImageIndexRef.current) {
          currentImageIndexRef.current = newIndex;
          setCurrentHeadImage(newIndex);
        }
        headParallaxRef.ref.current.style.transform = `translateY(${translateHeadY}vh)`;
      }
      const translateY = -progress * 100; // Adjust the multiplier as needed for the desired effect
      // Apply the transform to the element
      if (titleParallaxRef.ref.current) {
        titleParallaxRef.ref.current.style.transform = `translateY(${translateY}vh)`;
      }
      const descTranslateY = progress * -10; // Example calculation
      if (descriptionParallaxRef.ref.current) {
        if (window.innerWidth <= 768) { // Mobile view
          descriptionParallaxRef.ref.current.style.transform = `translateY(${translateY}vh)`; // Different transform for mobile
        } else { // Desktop view
          if (progress > 0.2) {
            descriptionParallaxRef.ref.current.style.setProperty(
              "--hero-desc-opacity",
              0.9 - progress * 2 + ""
            );
          }
          descriptionParallaxRef.ref.current.style.transform = `translateY(${descTranslateY}vh)`;
        }
      }

      if (arrowParallaxRef.ref.current) {
        arrowParallaxRef.ref.current.style.setProperty(
          "--hero-arrow-rotate",
          Math.min(Math.floor(progress * 12 * 180), 180) + "deg"
        );
        if (progress > 0.3) {
          arrowParallaxRef.ref.current.style.setProperty(
            "--hero-arrow-opacity",
            1.3 - progress * 3 + ""
          );
        } else {
          arrowParallaxRef.ref.current.style.setProperty(
            "--hero-arrow-opacity",
            1 + ""
          );
        }
      }

      if (progress < 0.4 && nicheParallaxRef.ref.current && tensorParallaxRef.ref.current) {
        if (window.innerWidth <= 768) { // Mobile view
          nicheParallaxRef.ref.current.style.setProperty(
            "--niche-pos-x",
            progress * 85 + "vw"
          );
          tensorParallaxRef.ref.current.style.setProperty(
            "--tensor-pos-x",
            -progress * 60 + "vw"
          );
        } else {
          nicheParallaxRef.ref.current.style.setProperty(
            "--niche-pos-x",
            -progress * 20 + "vw"
          );
          tensorParallaxRef.ref.current.style.setProperty(
            "--tensor-pos-x",
            progress * 60 + "vw"
          );
        }
      } else if (nicheParallaxRef.ref.current && tensorParallaxRef.ref.current) {
        nicheParallaxRef.ref.current.style.setProperty(
          "--niche-pos-y",
          40 - ((progress - 0.4) * 0.5 + 0.4) * 100 + 'vh'
        );
        tensorParallaxRef.ref.current.style.setProperty(
          "--tensor-pos-y",
          40 - ((progress - 0.4) * 0.5 + 0.4) * 100 + 'vh'
        );
      }
    }
  })
  return (
    <>
      <section className='bg-gray-500 section-hero' id='home'>
        <div className="md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 md:px-10 2xl:px-20 pt-15 md:pt-5 relative hero-section-content">
          <div className='pt-[6vh] md:pt-0 3xl:pt-10' ref={titleParallaxRef.ref as React.RefObject<HTMLDivElement>} >
            <h1 className='hero-slogan whitespace-normal md:whitespace-pre-wrap uppercase text-[50px] md:text-[70px] leading-[50px] md:leading-[70px] xl:leading-[70px] pb-[20px] '>{title}</h1>
            <p className='uppercase text-[14px] md:text-5 leading-[14px] md:leading-5 w-[136px] md:w-fit'>{subtitle}</p>
          </div>
          <div className="flex justify-end w-[167px] md:w-full md:mt-[-10vh] md:mb-[10vh]">
            <p className='text-[14px] md:text-[10px] lg:text-[16px] opacity-50 whitespace-normal md:whitespace-pre-wrap mt-[20px] leading-[20px] md:mt-[26px] mb-[48px] font-inter hero-desc-opacity md:w-[200px]' ref={descriptionParallaxRef.ref as React.RefObject<HTMLDivElement>}>{description}</p>
          </div>
          <div ref={headParallaxRef.ref} className='hero-img-wrapper w-full absolute z-[8] section-hero_charactor bottom-[-40vh] md:bottom-[-20vh] lg:bottom-[-40vh] xl:bottom-0'>
            {headImgs.map((img, index) => (
              <img
                src={img}
                key={index}
                className={`mt-[-30%] relative ml-[3%] ${currentHeadImage === index ? 'opacity-100' : 'opacity-0'} `}
                alt="Parallax"
              />
            ))}
          </div>
          <div className='flex justify-between flex-col md:flex-row xl:mt-[-12vh] 3xl:mt-0'>
            <p className='text-blue-500 text-[70px] xl:text-[190px] leading-[70px] xl:leading-[190px] niche-parallax z-10' ref={nicheParallaxRef.ref as React.RefObject<HTMLDivElement>}>NICHE</p>
            <p className='text-blue-500 text-right text-[70px] xl:text-[190px] leading-[70px] xl:leading-[190px] tensor-parallax z-10 xl:z-[5]' ref={tensorParallaxRef.ref as React.RefObject<HTMLDivElement>}>TENSOR</p>
          </div>
          <div className='flex justify-center relative z-10'>
            <div className='hero-arrow-opacity' ref={arrowParallaxRef.ref as React.RefObject<HTMLDivElement>}>
              <div className='arrow-icon'>
                <ArrowIcon />
              </div>
              <div className='arrow-mobile-icon'>
                <ArrowMobileIcon />
              </div>
            </div>
          </div>
          <div className="empty"></div>
        </div>
      </section>
      <div ref={divParallaxRef.ref as React.RefObject<HTMLDivElement>}>
      </div>
    </>
  );
};

export default HeroSection;