import React, { useState } from 'react';
import Logo from '../assets/images/logo-home.svg';
import { useAppContext } from '../utils/AppContext';
import Avatar from './common/Avatar';
import { Link } from 'react-router-dom';
import { getUserInfo } from '../utils/apiServices';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { setIsSignUpModalOpen, userInfo, setUserInfo, setIsProfileModalOpen, signOut } = useAppContext();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) document.body.classList.remove('overflow-hidden');
    else document.body.classList.add('overflow-hidden');
  };
  
  const openProfileModal = async () => {
    setIsProfileModalOpen(true)
    const updatedUserInfo = await getUserInfo(userInfo?.auth_key);
    setUserInfo({
      email: updatedUserInfo.user.email,
      auth_key: updatedUserInfo.user._id,
      credit: updatedUserInfo.user.credit,
      request_count: updatedUserInfo.user.request_count
    });
  };

  return (
    <header className="bg-gray-500">
      <div className="m-auto md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] p-5 md:p-10 2xl:p-20 2xl:pt-[44px] pb-0 md:pb-0 2xl:pb-0 flex items-center justify-between">
        <Link to="/" >
          <img src={Logo} alt="Logo" className='h-8 md:h-auto' />
        </Link>
        <div className={`header-nav-menu ${isMenuOpen && 'active'} z-[100]`}>
          <nav>
            <ul className={`flex flex-col md:flex-row space-x-0 md:space-x-10 md:flex`}>
              <li>
                <Link to="/" className="text-base uppercase">Home</Link>
              </li>
              <li>
                <Link to="/image-api" className="text-base uppercase">Image API</Link>
              </li>
              <li>
                <Link to="/text-api" className="text-base uppercase">Text API</Link>
              </li>
              <li>
                <Link to="https://app.nichetensor.com" className="text-base uppercase">Studio</Link>
              </li>
              <li>
                <Link to="https://app.nichetensor.com/?feature=chatCompletions" className="text-base uppercase">Chat</Link>
              </li>
              {userInfo?.email && (
                <li>
                  <Link to="/dashboard" className="text-base uppercase">Dashboard</Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
        {userInfo?.email === '' ? (
          <button className="bg-blue-500 hover:bg-blue-700 text-white uppercase text-base py-3 px-5 md:py-4 md:px-6 rounded-[100px] z-[100]"
            onClick={() => setIsSignUpModalOpen(true)}
          >
            Get Started
          </button>
        ) : (
          <div className='cursor-pointer relative z-[100]' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <Avatar userName={userInfo?.email} />
            {isDropdownOpen && (
              <div className='absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none right-0 z-10' role='menu' aria-orientation='vertical' aria-labelledby='user-menu-button'>
                <span className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer' role='menuitem' onClick={openProfileModal}>User Profile</span>
                <span className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer' role='menuitem' onClick={() => signOut()}>Sign Out</span>
              </div>
            )}
          </div>
        )}
        <button onClick={toggleMenu} className={`md:hidden hamburger ${isMenuOpen && 'active'}`}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`menu-overlay fixed z-[99] w-full h-full left-0 bottom-0 ${isMenuOpen ? 'block' : 'hidden'}`} onClick={() => toggleMenu()} />
      </div>
    </header>
  );
};

export default Header;