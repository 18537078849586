import React from 'react';
import ReactDOM from 'react-dom';
import closeIcon from '../../assets/images/close-icon.svg';

const Modal = ({ isOpen, onClose, children }: { isOpen: boolean, onClose: () => void, children: React.ReactNode }) => {
  if (!isOpen) return null;

  const onModalClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }
  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center" onClick={onModalClick}>
      <div className="bg-white p-8 rounded-[16px] md:rounded-[30px] relative w-[300px] sm:w-[387px]">
        <button className='absolute right-5 z-[99]' onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </button>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root') as Element
  );
};

export default Modal;