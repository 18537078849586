import React from 'react';
import ContactSection from '../components/home/ContactSection';
import HeroSection from '../components/home/HeroSection';
import MainSection from '../components/home/MainSection';

const Home: React.FC = () => {
  const title = `Affordable Image
&Text Generation
API`;
  const subtitle = ``;
  const description = `The NicheTensor API supports cutting edge
models such as FluxSchnell,
Kolors, SUPIR and
many more!`;
  return (
    <>
      <HeroSection title={title} subtitle={subtitle} description={description} />
      <MainSection />
      <ContactSection />
    </>
  );
};

export default Home;
