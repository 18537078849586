import React from 'react';
import Logo from './icons/Logo';
import NicheTensorIcon from './icons/NicheTensorLogo';
import { useParallax } from 'react-scroll-parallax';

const Footer: React.FC = () => {
  const nicheLogoParallaxRef = useParallax({
    rotate: [0, 1080],
  });
  return (
    <footer className='py-[60px] md:py-[120px] bg-blue-500 flex flex-col items-center'>
      <div className="mb-6">
        <div ref={nicheLogoParallaxRef.ref as React.RefObject<HTMLDivElement>}>
          <Logo color='white' />
        </div>
      </div>
      <div className='mb-10'>
        <NicheTensorIcon color='white' />
      </div>
      <p className='text-white opacity-50 text-[14px] leading-[16.94px] font-inter'>© 2024 by NicheTensor. All rights reserved.</p>
    </footer>
  );
};

export default Footer;