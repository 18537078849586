import React from "react";

const GraphsSection: React.FC = () => {
  return (
    <section className="overflow-hidden w-[790px] px-5 md:px-10">
      <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white mt-14 flex flex-col items-start">
        <p className="font-semibold text-[20px]">Balance</p>
        <p className="font-bold text-[40px] mt-4">$500.62</p>
        <p className="font-normal text-[14px] text-[#00000080]">USD</p>
        <div className="w-full border border-solid border-[#F2F2F2] mt-4"></div>
        <p className="font-medium text-[14px] mt-4">Track your spend for each month</p>
        <div className="w-full h-[161px] bg-[#E3E3E3] rounded-[15px] mt-4"></div>
      </div>
      <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white mt-4 flex flex-col items-start">
        <p className="font-semibold text-[20px]">Current Account Balance</p>
        <p className="font-normal text-[14px] text-[#00000080]">Your account balance in real-time</p>
        <div className="w-full h-[150px] mt-4"></div>
      </div>
      <div className="w-full rounded-[16px] md:rounded-[30px] p-8 bg-white my-4 flex flex-col items-start">
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col items-start">
            <p className="font-semibold text-[14px] sm:text-[20px]">Current Usage by Endpoint</p>
            <p className="font-normal text-[12px] sm:text-[14px] text-[#00000080]">Track endpoints usage in real-time</p>
          </div>
          <div className="flex py-1 sm:py-2 px-[6px] sm:px-4 rounded-[100px] text-[#001AFF] text-[12px] sm:text-[14px] bg-[#e5e7eb]">
            <p className="text-center">Since 11:52, 28 August</p>
          </div>
        </div>
        <div className="mt-6 flex justify-between items-center w-full">
          <p className="text-[12px] sm:text-[14px] font-normal">Vision/Text to Image</p>
          <div className="w-[566px] h-[6px] bg-[#001AFF]"></div>
        </div>
        <div className="w-full h-[100px]"></div>
      </div>
    </section>
  )
}

export default GraphsSection;
