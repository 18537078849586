import React from 'react';

const Credit: React.FC = () => {
  return (
    <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto relative pt-[80px] md:pt-[146px] pb-[120px] flex flex-col items-center gap-[40px] md:gap-[80px]' >
      <h2 className='uppercase text-[32px] md:text-[50px] leading-[32px] md:leading-[63.35px]'>Let's get started</h2>
      <div className="flex flex-col md:flex-row gap-[40px] md:gap-0">
        <div className="flex flex-col items-center gap-[12px] md:px-[60px] border-right-gray">
          <p className='text-[20px] leading-[24.2px] opacity-70 font-inter'>First</p>
          <p className='text-blue-500 text-[46px] leading-[55.67px] font-inter font-semibold'>10.000</p>
          <p className='text-[20px] leading-[24.2px] font-inter capitalize'>generations for free!</p>
        </div>
        <div className="flex flex-col items-center gap-[12px] md:px-[60px]">
          <p className='text-[20px] leading-[24.2px] opacity-70 font-inter'>Then</p>
          <p className='text-blue-500 text-[46px] leading-[55.67px] font-inter font-semibold'>$0.01</p>
          <p className='text-[20px] leading-[24.2px] font-inter capitalize'>per generation</p>
        </div>
      </div>
      <a
        href="mailto:contact@nichetensor.com?subject=Request%20Credits&body=I%20need%20more%20credit."
        className="transition-all duration-300 bg-blue-500 hover:bg-blue-700 text-white uppercase md:text-[16px] md:leading-[20.27px] py-3 px-5 md:py-4 md:px-6 rounded-[100px]"
      >
        Request credits
      </a>
    </div>
  );
};

export default Credit;