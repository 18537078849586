import React from 'react';

type LogoProps = {
  color: string;
};

const Logo: React.FC<LogoProps> = ({ color = '#001AFF'}) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M24.9988 0C26.1327 0 27.2459 0.0838234 28.34 0.229411L18.0709 18.675L10.1312 4.90293C11.515 3.87793 13.0092 2.99558 14.5915 2.27058L17.9209 8.28381L22.518 0.123529C23.3341 0.042647 24.1606 0 24.9988 0ZM45.3721 10.5203C46.5118 12.1218 47.4677 13.8615 48.2089 15.7115L27.3545 15.5909L35.228 2.18945C36.8354 2.91151 38.353 3.79533 39.7589 4.82474L36.3692 10.4188L45.3721 10.5203ZM42.9918 27.8749L49.8183 27.9499C49.9315 26.9823 49.9977 25.9984 49.9977 24.9999C49.9977 24.2381 49.958 23.4867 49.8918 22.744L33.9551 22.7411L44.6051 40.5014C45.8403 38.9396 46.8933 37.2293 47.733 35.3969L42.9918 27.8749ZM1.95735 15.2851C2.75294 13.4013 3.77205 11.6351 4.98234 10.0204L15.1529 27.8601L0.169117 27.8734C0.0617646 26.9292 0 25.9719 0 24.9984C0 24.2101 0.0397058 23.4322 0.111765 22.6616L6.10734 22.7454L1.95735 15.2851ZM2.18164 35.2118C3.0184 37.0794 4.07723 38.8235 5.32575 40.4147L13.0449 40.4441L10.1184 45.0882C11.564 46.1617 13.1316 47.0764 14.7934 47.8206L21.9757 35.1279L2.18164 35.2118ZM24.9979 50C23.8612 50 22.7435 49.9162 21.6465 49.7692L31.3847 32.481L39.4538 45.3898C38.0156 46.4118 36.467 47.2883 34.8259 47.9912L31.7553 42.8662L27.7715 49.8427C26.8597 49.9442 25.9362 50 24.9979 50Z" fill={color} />
    </svg>
  );
};

export default Logo;