import React from 'react';
import Feature1 from "../../assets/images/feature1.png"
import Feature2 from "../../assets/images/feature2.png"
import Feature3 from "../../assets/images/feature3.png"
import Feature4 from "../../assets/images/feature4.png"
import Feature5 from "../../assets/images/feature5.jpg"
import Feature6 from "../../assets/images/feature6.png"
import ImageFeatureCard from './ImageFeatureCard';

const features = [
  {
    img: Feature1,
    title: 'GoJourney',
    description: 'Ignite your creativity and explore endless innovation'
  }, {
    img: Feature2,
    title: 'Sticker Maker',
    description: 'Create flawless stickers with AI-driven designs and transparent backgrounds'
  }, {
    img: Feature3,
    title: 'FluxSchnell',
    description: 'Transform your text into stunning, visual art'
  }, {
    img: Feature4,
    title: 'Supir',
    description: 'Turn any face into 3D models, emojis, pixel art, or even claymation figures'
  }, {
    img: Feature5,
    title: 'Kolors',
    description: 'Use ControlNet to fine-tune and enhance images with precision'
  }, {
    img: Feature6,
    title: 'llama3_70b',
    description: 'Effortlessly redesign and reimagine existing visuals'
  }
]
const ImageAPIFeatures: React.FC = () => {
  return (
    <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] px-5 md:px-10 2xl:px-20 md:m-auto relative mt-5 md:mt-10 lg:mt-20'>
      <div className="bg-white rounded-[16px] md:rounded-[30px] p-[20px] 2xl:p-[60px] text-left md:text-center">
        <h2 className='text-black uppercase text-[32px] md:text-[50px] leading-[32px] md:leading-[50px] text-center md:text-left'>NICHETENSOR API</h2>
        <h2 className='text-blue-500 uppercase text-[28px] md:text-[50px] leading-[32px] md:leading-[50px] text-center md:text-left'>MODELS AND TOOLS</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[30px] md:gap-[15px] 2xl:gap-[60px] mt-[60px]">
          {features.map(feature => (
            <ImageFeatureCard key={feature.title} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageAPIFeatures;