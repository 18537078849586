import React from 'react';
import BgNicheLogo from '../icons/BgNicheLogo';
// import Discord from '../../assets/images/discord.svg'
import Email from '../../assets/images/email.svg'
// import GitHub from '../../assets/images/github.svg'
import { useParallax } from 'react-scroll-parallax';

const ContactSection: React.FC = () => {
  const description = `At NicheTensor, we are dedicated to democratizing AI, making it both accessible and customizable for all. Our suite of image and text generation services is designed with flexibility in mind, catering to individual and business needs alike. Whether you're an artist seeking creative tools or a developer in need of reliable AI models, NicheTensor provides tailored solutions that adapt to your unique requirements. Our commitment is to empower you with cutting-edge technology that enhances your projects and helps to drive innovation.`
  const nicheLogoParallaxRef = useParallax({
    rotate: [0, 360],
  });
  const title = `PERSONALIZED AI SOLUTIONS FOR EVERYONE`
  return (
    <section className="px-5 md:px-20 bg-black relative pb-[10px] overflow-hidden" id='contact'>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <div ref={nicheLogoParallaxRef.ref as React.RefObject<HTMLDivElement>}>
          <BgNicheLogo />
        </div>
      </div>
      <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 2xl:px-20 text-left md:text-center relative'>
        <h2 className='text-white uppercase text-[32px] 2xl:text-[50px] leading-[32px] 2xl:leading-[50px] pt-20 2xl:pt-60'>{title}</h2>
        <p className='center text-white font-inter text-[14px] 2xl:text-[20px] leading-[16.94px] 2xl:leading-[24.2px] opacity-70 mt-6 mb-10'>{description}</p>
        <div className="flex justify-center">
          <a target="_blank" rel="noopener noreferrer" href='https://docs.nichetensor.com/' className="bg-blue-500 hover:bg-blue-700 text-white uppercase text-[14px] md:text-base py-3 md:py-4 px-5 md:px-6 rounded-[100px] transition-all duration-300">
            Learn more
          </a>
        </div>
        <div className='flex mb-[80px] 2xl:mb-[180px] justify-between mt-[80px] 2xl:mt-[180px] items-center flex-col md:flex-row gap-[40px]'>
          <h3 className='text-white uppercase text-[32px] 2xl:text-[50px] leading-[32px] 2xl:leading-[50px] text-center w-full flex-1'>Get in touch</h3>
          <div className='flex justify-center gap-[40px] md:gap-[180px] flex-1'>
            {/* <a className='shrink-0' href="https://discord.com" target="_blank" rel="noopener noreferrer">
              <div className='cursor-pointer'>
                <img src={Discord} alt="Discord" />
                <p className='text-[16px] text-white mt-8 text-center'>Discord</p>
              </div>
            </a> */}
            <a className='shrink-0' href="mailto:contact@nichtetensor.com" rel="noopener noreferrer">
              <div className='cursor-pointer hover:opacity-80 transition-opacity duration-300'>
                <img src={Email} alt="Discord" />
                <p className='text-[16px] text-white mt-8 text-center'>Email</p>
              </div>
            </a>
            {/* <a className='shrink-0' href="https://github.com" target="_blank" rel="noopener noreferrer">
              <div className='cursor-pointer'>
                <img src={GitHub} alt="Discord" />
                <p className='text-[16px] text-white mt-8 text-center'>GitHub</p>
              </div>
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;