import React from 'react';
import Email from '../../assets/images/email.svg'

const ImageAPIContactSection: React.FC = () => {
  return (
    <section className='bg-black'>
      <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] py-[40px] 2xl:py-[120px] m-auto px-5 2xl:px-20 text-left md:text-center relative'>
        <div className='flex justify-between items-center flex-col md:flex-row gap-[40px]'>
          <h3 className='text-white uppercase text-[32px] 2xl:text-[50px] leading-[32px] 2xl:leading-[50px] text-center w-full flex-1'>Get in touch</h3>
          <div className='flex justify-center gap-[40px] md:gap-[180px] flex-1'>
            {/* <a className='shrink-0' href="https://discord.com" target="_blank" rel="noopener noreferrer">
              <div className='cursor-pointer'>
                <img src={Discord} alt="Discord" />
                <p className='text-[16px] text-white mt-8 text-center'>Discord</p>
              </div>
            </a> */}
            <a className='shrink-0' href="mailto:contact@nichtetensor.com" rel="noopener noreferrer">
              <div className='cursor-pointer hover:opacity-80 transition-opacity duration-300'>
                <img src={Email} alt="Discord" />
                <p className='text-[16px] text-white mt-8 text-center'>Email</p>
              </div>
            </a>
            {/* <a className='shrink-0' href="https://github.com" target="_blank" rel="noopener noreferrer">
              <div className='cursor-pointer'>
                <img src={GitHub} alt="Discord" />
                <p className='text-[16px] text-white mt-8 text-center'>GitHub</p>
              </div>
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageAPIContactSection;