import React from 'react';

interface IImageFeatureCardProps {
  img: any;
  title: string;
  description: string;
}

const ImageFeatureCard: React.FC<IImageFeatureCardProps> = ({ img, title, description }) => {
  return (
    <div className="image-feature-card p-0 md:p-4 text-left">
      <img src={img} alt={title} className='h-[200px] rounded-[20px] object-cover w-full'/>
      <h2 className='text-[24px] leading-[30.41px] mt-4 mb-3 text-center md:text-left'>{title}</h2>
      {/* <p className='text-[16px] leading-[19.46px] font-inter'>{description}</p> */}
    </div>
  );
};

export default ImageFeatureCard;