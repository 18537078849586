import React from 'react';

const NicheTensorIcon = ({ color = 'white' }) => {
  return (
    <svg width="148" height="21" viewBox="0 0 148 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M143.31 11.775V19.5H139.935V5.15002H143.16V8.20002C143.293 7.91669 143.485 7.60002 143.735 7.25002C143.985 6.90002 144.285 6.57502 144.635 6.27502C144.985 5.95836 145.376 5.69169 145.81 5.47502C146.26 5.25836 146.743 5.15002 147.26 5.15002H147.735V9.00002C147.668 8.96669 147.56 8.94169 147.41 8.92502C147.26 8.89169 147.135 8.87502 147.035 8.87502C146.468 8.87502 145.951 8.97502 145.485 9.17502C145.035 9.35835 144.643 9.59168 144.31 9.87502C143.993 10.1583 143.743 10.475 143.56 10.825C143.393 11.1583 143.31 11.475 143.31 11.775Z" fill={color} />
      <path d="M129.961 4.42505C131.161 4.42505 132.269 4.63338 133.286 5.05005C134.303 5.46671 135.178 6.03338 135.911 6.75005C136.661 7.45004 137.244 8.28338 137.661 9.25004C138.078 10.2167 138.286 11.2417 138.286 12.325C138.286 13.4084 138.078 14.4334 137.661 15.4C137.244 16.3667 136.661 17.2084 135.911 17.925C135.178 18.625 134.303 19.1834 133.286 19.6C132.269 20.0167 131.161 20.225 129.961 20.225C128.778 20.225 127.669 20.0167 126.636 19.6C125.619 19.1834 124.744 18.625 124.011 17.925C123.278 17.2084 122.694 16.3667 122.261 15.4C121.844 14.4334 121.636 13.4084 121.636 12.325C121.636 11.2417 121.844 10.2167 122.261 9.25004C122.694 8.28338 123.278 7.45004 124.011 6.75005C124.744 6.03338 125.619 5.46671 126.636 5.05005C127.669 4.63338 128.778 4.42505 129.961 4.42505ZM129.961 7.30004C129.278 7.30004 128.644 7.43338 128.061 7.70004C127.478 7.96671 126.969 8.32504 126.536 8.77504C126.119 9.22504 125.786 9.75837 125.536 10.375C125.303 10.9917 125.186 11.6417 125.186 12.325C125.186 13.0084 125.303 13.6584 125.536 14.275C125.786 14.8917 126.119 15.425 126.536 15.875C126.969 16.325 127.478 16.6834 128.061 16.95C128.644 17.2167 129.278 17.35 129.961 17.35C130.644 17.35 131.278 17.2167 131.861 16.95C132.461 16.6834 132.969 16.325 133.386 15.875C133.819 15.425 134.153 14.8917 134.386 14.275C134.636 13.6584 134.761 13.0084 134.761 12.325C134.761 11.6417 134.636 10.9917 134.386 10.375C134.153 9.75837 133.819 9.22504 133.386 8.77504C132.969 8.32504 132.461 7.96671 131.861 7.70004C131.278 7.43338 130.644 7.30004 129.961 7.30004Z" fill={color} />
      <path d="M115.85 4.65002C116.467 4.65002 117.05 4.71669 117.6 4.85002C118.167 4.96669 118.658 5.10836 119.075 5.27503V8.27502C118.542 8.00835 118.008 7.80835 117.475 7.67502C116.958 7.54169 116.508 7.47502 116.125 7.47502C115.558 7.47502 115.108 7.58335 114.775 7.80002C114.458 8.00002 114.3 8.28335 114.3 8.65002C114.3 8.90002 114.458 9.17502 114.775 9.47502C115.092 9.75835 115.6 10.125 116.3 10.575C118 11.675 119.133 12.6167 119.7 13.4C120.283 14.1833 120.575 14.9833 120.575 15.8C120.575 17.0333 120.108 18.0333 119.175 18.8C118.242 19.5667 116.958 19.9583 115.325 19.975C114.708 19.975 114 19.875 113.2 19.675C112.4 19.475 111.692 19.2417 111.075 18.975V15.975C111.842 16.3583 112.558 16.6417 113.225 16.825C113.892 17.0083 114.433 17.1 114.85 17.1C115.617 17.1 116.167 16.9667 116.5 16.7C116.85 16.4333 117.025 16.1167 117.025 15.75C117.025 15.5167 116.858 15.225 116.525 14.875C116.192 14.525 115.442 13.975 114.275 13.225C113.542 12.7417 112.942 12.3083 112.475 11.925C112.025 11.525 111.667 11.15 111.4 10.8C111.15 10.4334 110.975 10.0667 110.875 9.70002C110.792 9.31669 110.75 8.90835 110.75 8.47502C110.75 8.00835 110.842 7.55002 111.025 7.10002C111.225 6.63336 111.525 6.22502 111.925 5.87502C112.342 5.50836 112.875 5.21669 113.525 5.00002C114.175 4.76669 114.95 4.65002 115.85 4.65002Z" fill={color} />
      <path d="M99.3156 10.8501V19.5001H95.9406V5.15012H99.1656V6.72512C99.6323 6.20845 100.266 5.75012 101.066 5.35012C101.882 4.95012 102.816 4.75012 103.866 4.75012C105.382 4.75012 106.616 5.22512 107.566 6.17512C108.532 7.12512 109.016 8.42512 109.016 10.0751V19.5001H105.641V11.1001C105.641 9.85012 105.324 8.97512 104.691 8.47512C104.074 7.97512 103.349 7.72512 102.516 7.72512C102.166 7.72512 101.799 7.79178 101.416 7.92512C101.032 8.05845 100.682 8.25845 100.366 8.52512C100.066 8.79178 99.8156 9.12512 99.6156 9.52512C99.4156 9.90845 99.3156 10.3501 99.3156 10.8501Z" fill={color} />
      <path d="M86.5887 7.30004C85.5387 7.30004 84.6638 7.60004 83.9638 8.20004C83.2804 8.78338 82.8138 9.57504 82.5638 10.575H90.5887C90.3387 9.57504 89.8721 8.78338 89.1887 8.20004C88.5054 7.60004 87.6387 7.30004 86.5887 7.30004ZM94.1887 17.6C93.4054 18.3167 92.4637 18.9417 91.3637 19.475C90.2804 19.9917 88.9221 20.25 87.2887 20.25C86.0054 20.25 84.8387 20.05 83.7887 19.65C82.7387 19.25 81.8471 18.7 81.1138 18C80.3804 17.2834 79.8138 16.4417 79.4138 15.475C79.0138 14.5084 78.8138 13.4584 78.8138 12.325C78.8138 11.1917 79.0054 10.1417 79.3888 9.17504C79.7888 8.20838 80.3304 7.37505 81.0138 6.67505C81.7138 5.97505 82.5387 5.42505 83.4887 5.02505C84.4387 4.62505 85.4721 4.42505 86.5887 4.42505C87.6887 4.42505 88.7137 4.62505 89.6637 5.02505C90.6304 5.42505 91.4554 5.97505 92.1387 6.67505C92.8221 7.37505 93.3554 8.20838 93.7387 9.17504C94.1387 10.1417 94.3387 11.1917 94.3387 12.325V13.175H82.4138C82.5638 14.4584 83.0471 15.475 83.8637 16.225C84.6971 16.975 85.8387 17.35 87.2887 17.35C88.3887 17.35 89.3387 17.175 90.1387 16.825C90.9387 16.475 91.6137 16.025 92.1637 15.475L94.1887 17.6Z" fill={color} />
      <path d="M71.8427 19.5002V4.10021H67.8927V0.85022H79.4177V4.10021H75.4677V19.5002H71.8427Z" fill={color} />
      <path d="M60.0995 7.30004C59.0495 7.30004 58.1745 7.60004 57.4745 8.20004C56.7912 8.78338 56.3245 9.57504 56.0745 10.575H64.0995C63.8495 9.57504 63.3828 8.78338 62.6995 8.20004C62.0162 7.60004 61.1495 7.30004 60.0995 7.30004ZM67.6995 17.6C66.9162 18.3167 65.9745 18.9417 64.8745 19.475C63.7912 19.9917 62.4328 20.25 60.7995 20.25C59.5162 20.25 58.3495 20.05 57.2995 19.65C56.2495 19.25 55.3578 18.7 54.6245 18C53.8912 17.2834 53.3245 16.4417 52.9245 15.475C52.5245 14.5084 52.3245 13.4584 52.3245 12.325C52.3245 11.1917 52.5162 10.1417 52.8995 9.17504C53.2995 8.20838 53.8412 7.37505 54.5245 6.67505C55.2245 5.97505 56.0495 5.42505 56.9995 5.02505C57.9495 4.62505 58.9828 4.42505 60.0995 4.42505C61.1995 4.42505 62.2245 4.62505 63.1745 5.02505C64.1412 5.42505 64.9662 5.97505 65.6495 6.67505C66.3328 7.37505 66.8662 8.20838 67.2495 9.17504C67.6495 10.1417 67.8495 11.1917 67.8495 12.325V13.175H55.9245C56.0745 14.4584 56.5578 15.475 57.3745 16.225C58.2078 16.975 59.3495 17.35 60.7995 17.35C61.8995 17.35 62.8495 17.175 63.6495 16.825C64.4495 16.475 65.1245 16.025 65.6745 15.475L67.6995 17.6Z" fill={color} />
      <path d="M41.0148 10.8501V19.5001H37.6398V0.00012207H40.8648V6.72511C41.3315 6.20845 41.9648 5.75011 42.7648 5.35011C43.5815 4.95011 44.5148 4.75011 45.5648 4.75011C47.0815 4.75011 48.3148 5.22511 49.2648 6.17511C50.2315 7.12511 50.7148 8.42511 50.7148 10.0751V19.5001H47.3398V11.1001C47.3398 9.85011 47.0231 8.97511 46.3898 8.47511C45.7731 7.97511 45.0481 7.72511 44.2148 7.72511C43.8648 7.72511 43.4981 7.79178 43.1148 7.92511C42.7315 8.05844 42.3815 8.25844 42.0648 8.52511C41.7648 8.79178 41.5148 9.12511 41.3148 9.52511C41.1148 9.90844 41.0148 10.3501 41.0148 10.8501Z" fill={color} />
      <path d="M35.6839 16.2V19.25C35.5172 19.3667 35.2922 19.4667 35.0089 19.55C34.7255 19.65 34.4172 19.7334 34.0839 19.8C33.7672 19.8667 33.4422 19.9084 33.1089 19.925C32.7922 19.9584 32.5005 19.975 32.2339 19.975C31.0339 19.975 29.9172 19.7834 28.8839 19.4C27.8505 19 26.9589 18.4584 26.2089 17.775C25.4589 17.0917 24.8672 16.2834 24.4339 15.35C24.0172 14.4167 23.8089 13.4084 23.8089 12.325C23.8089 11.2584 24.0172 10.2584 24.4339 9.32504C24.8672 8.39171 25.4589 7.58338 26.2089 6.90005C26.9589 6.20005 27.8505 5.65838 28.8839 5.27505C29.9172 4.87505 31.0339 4.67505 32.2339 4.67505C32.5005 4.67505 32.7922 4.69172 33.1089 4.72505C33.4422 4.74172 33.7672 4.78338 34.0839 4.85005C34.4005 4.91672 34.7005 5.00005 34.9839 5.10005C35.2839 5.18338 35.5172 5.28338 35.6839 5.40005V8.45004H35.5839C35.1172 8.06671 34.5672 7.82504 33.9339 7.72504C33.3172 7.60838 32.7505 7.55005 32.2339 7.55005C30.8339 7.55005 29.6672 8.00838 28.7339 8.92504C27.8172 9.84171 27.3589 10.975 27.3589 12.325C27.3589 13.6917 27.8172 14.8334 28.7339 15.75C29.6672 16.65 30.8339 17.1 32.2339 17.1C32.7505 17.1 33.3172 17.0417 33.9339 16.925C34.5672 16.8084 35.1172 16.5667 35.5839 16.2H35.6839Z" fill={color} />
      <path d="M22.1697 0V3.125H18.6947V0H22.1697ZM22.1197 5.14999V19.5H18.7447V5.14999H22.1197Z" fill={color} />
      <path d="M12.8153 19.5002L3.89029 7.02521V19.5002H0.265297V0.85022H3.79029L12.7403 13.4752V0.85022H16.3653V19.5002H12.8153Z" fill={color} />
    </svg>
  );
};

export default NicheTensorIcon;