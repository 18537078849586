const backendUrl = process.env.REACT_APP_BACKEND_URL;

export interface ILoginData {
  email: string;
  password: string;
}

export interface IErrorResponse {
  detail: string;
}
export interface ISignResponseData {
  detail: any;
  message: string;
  user: {
    email: string;
    _id: string;
    credit: number;
    request_count: number;
  }
}


// Type guard for IErrorResponse
export function isIErrorResponse(data: any): data is IErrorResponse {
  return (data as IErrorResponse).detail !== undefined;
}

// Type guard for ISignResponseData
export function isISignResponseData(data: any): data is ISignResponseData {
  return (data as ISignResponseData).user !== undefined;
}

export async function signin(data: ILoginData): Promise<ISignResponseData | IErrorResponse> {
  const response = await fetch(`${backendUrl}signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  return result;
}

export async function signup(data: ILoginData): Promise<ISignResponseData> {
  const response = await fetch(`${backendUrl}signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  return result;
}

export async function getUserInfo(apikey: string): Promise<ISignResponseData> {
  const response = await fetch(`${backendUrl}get_user_info`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'API_KEY': apikey
    }
  });
  const result = await response.json();
  return result;
}