import React from 'react';
import ImageAPIHeroSection from '../components/image-api/ImageAPIHeroSection';
import MoreResourcesSection from '../components/image-api/MoreResourcesSection';
import ImageAPIContactSection from '../components/image-api/ImageAPIContactSection';

const ImageAPI: React.FC = () => {

  return (
    <>
      <ImageAPIHeroSection />
      <MoreResourcesSection />
      <ImageAPIContactSection />
    </>
  );
};

export default ImageAPI;