import React, { useEffect, useState } from "react";
import RobotImg from '../../assets/images/Robot Head2 1.png';
import DeleteImg from '../../assets/images/delete.svg';
import CopyImg from '../../assets/images/copy.svg';
import CopySuccessImg from '../../assets/images/copy-success.svg';
import EmailImg from '../../assets/images/small-email.svg';
import Modal from "../common/Modal";
import Input from "../common/Input";

const MockAPIKeyArray = [
  {
    id: 1,
    name: "Hanyang1",
    key: "fe08fe08fe08fe08fe08fe08fe081cd79c",
    date: "Aug 29, 2024, 12:00:00 AM",
    isCopied: false,
  },
  {
    id: 2,
    name: "Hanyang2",
    key: "fe08fe08fe08fe08fe08fe08fe081cd79c",
    date: "Aug 29, 2024, 12:00:00 AM",
    isCopied: false,
  }
]

interface IApiKey {
  id: number;
  name: string;
  key: string;
  date: string;
  isCopied: boolean;
}

const DashboardSection: React.FC = () => {
  const [apiKeys, setApiKeys] = useState<Array<IApiKey>>(MockAPIKeyArray);
  const [openAddBalanceModal, setOpenAddBalanceModal] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [addBalanceValue, setAddBalanceValue] = useState("0");
  const [isShowMinError, setIsShowMinError] = useState(false);

  const handleAddAPIKey = () => {
    setApiKeys([...apiKeys, 
      {id: apiKeys[apiKeys.length-1].id + 1, 
      name: `Hanyang${apiKeys[apiKeys.length-1].id + 1}`,
      key: "fe08fe08fe08fe08fe08fe08fe081cd79c",
      date: "Aug 29, 2024, 12:00:00 AM",
      isCopied: false,}
    ])
  }
  const handleDeleteAPIKey = (e: React.MouseEvent<HTMLButtonElement>) => {
    const filteredArray = apiKeys.filter((key) => key.id.toString() !== e.currentTarget.id);
    setApiKeys(filteredArray);
  }

  const handleAPIKeyCopy = (id: number, key: string) => {
    navigator.clipboard.writeText(key).then(
      () => {
        setApiKeys(apiKeys.map(value => ({
          ...value,
          isCopied: value.id === id
        })));
        setTimeout(() => setApiKeys(apiKeys.map(value => ({
          ...value,
          isCopied: false,
        }))), 2000); 
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  const handleMaskString = (key: string, maskedLength: number) => {
    const start = key.slice(0, 4); 
    const end = key.slice(-5); 
    const maskChar = "*";
    const masked = maskChar.repeat(maskedLength); 

    return `${start}${masked}${end}`;
  }

  useEffect(() => {
    if (Number(addBalanceValue) < 2) setIsShowMinError(true);
    else setIsShowMinError(false); 
  }, [])

  const handleChage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value)) {
      setAddBalanceValue(event.target.value);
      if (Number(event.target.value) < 2) setIsShowMinError(true);
      else setIsShowMinError(false); 
    }
    else return;
  }

  return (
    <section className="md:px-10 overflow-hidden w-[790px]" >
      <div className="relative w-full h-[131px] bg-white mt-14 rounded-[16px] md:rounded-[30px] block md:flex md:justify-center md:items-center p-5 sm:p-10">
        <div className="w-[40%] sm:w-1/2 flex flex-col">
          <p className="font-bold text-[12px] sm:text-[24px] text-black uppercase text-start">Affordable Image&Text</p>
          <p className="font-bold text-[12px] sm:text-[24px] text-black uppercase text-start sm:mt-[-10px]">Generation API</p>
          <p className="text-[12px] text-[#00000080] mt-[8px] text-start">That supports the latest models</p>
        </div>
        <div className="absolute md:relative right-[-70px] bottom-0 flex justify-center w-[100%] md:w-[60%] sm:w-1/2">
          <img src={RobotImg} alt="robot" className="w-[248px] mt-[-35px] mr-[-40px] sm:mr-0" />
        </div>
      </div>
      <div className="mt-5 block md:flex gap-3">
        <div className="w-full md:w-1/2 h-[260px] md:h-[310px] p-5 sm:p-10 rounded-[16px] md:rounded-[30px] bg-white flex flex-col items-start">
          <p className="font-semibold text-[20px]">Balance</p>
          <p className="font-bold text-[40px] mt-8 md:mt-16">$500.62</p>
          <p className="font-bold text-[14px] mt-2 text-[#00000080]">USD</p>
          <div className="w-full mt-5 flex justify-center">
          <Modal isOpen={openAddBalanceModal} onClose={() => setOpenAddBalanceModal(false)}>
            <div>
              <p className="font-semibold text-[20px] mt-1">Add to Balance</p>
            </div>
            <div className="mt-3">
              <p className="text-[14px] text-[#00000080]">Choose Amount</p>
            </div>
            <div className="mt-6">
              <div className="relative">
                <input
                  className="appearance-none pl-10 border-gray-300 hover:border-gray-400 transition-colors rounded-md w-full py-2 px-3 text-gray-800 leading-tight outline-none text-[40px]"
                  id="AddBalance"
                  type="text"
                  onChange={(event) => handleChage(event)}
                  value={addBalanceValue} 
                />
                <div className="absolute right-0 inset-y-0 flex items-center">
                  <p className="font-bold text-[16px]">USDT</p>
                </div>
                <div className="absolute left-0 inset-y-0 flex items-center">
                  <p className="font-bold text-[40px]">$</p>
                </div>
              </div>
            </div>
            {isShowMinError && 
              <div className="mt-3">
                <p className="text-[14px] text-[#E50000]">Amount must be greater than $2</p>
              </div>
            }
            <div className="w-full border border-solid border-[#F2F2F2] mt-6"></div>
            <div className="mt-3">
              <p className="text-[14px] text-[#00000080]">Please note: There is a minimum top-up amopunt of $2 USDT</p>
            </div>
            <div className="w-full mt-6 flex justify-center">
              <button className="py-4 px-6 text-white bg-[#001AFF] hover:bg-blue-700 rounded-[100px] w-full h-[50px] flex items-center justify-center text-[14px]">Top-up Balance</button>
            </div>
          </Modal>
            <button 
              className="py-4 px-6 text-white bg-[#001AFF] hover:bg-blue-700 rounded-[100px] w-full h-[50px] flex items-center justify-center"
              onClick={() => setOpenAddBalanceModal(true)}
            >
              Add balance
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 h-[260px] md:h-[310px] p-5 sm:p-10 rounded-[16px] md:rounded-[30px] bg-white flex flex-col items-start mt-5 md:mt-0">
          <p className="font-semibold text-[20px]">Account Level</p>
          <p className="font-bold text-[40px] mt-8 md:mt-16">Standard</p>
          <div className="w-full mt-12 justify-center">
            <Modal isOpen={openUpgradeModal} onClose={() => setOpenUpgradeModal(false)}>
              <div className="w-full h-[85px] rounded-[15px] bg-[#F2F2F2] mt-16 relative">
                <img src={RobotImg} alt="robot" className="absolute bottom-0 w-[218px] left-[20%]" />
              </div>
              <div className="mt-6">
                <p className="font-normal text-[14px] text-[#00000080]">API Platform</p>
              </div>
              <div className="flex justify-between items-center mt-3">
                <p className="font-bold text-[32px]">Upgrade to Pro</p>
              </div>
              <div className="mt-3">
                <p className="text-[14px] text-[#00000080]">To get started with higher rate-limits, more keys and personalised support, get in touch below.</p>
              </div>
              <div className="w-full mt-6 flex justify-center">
                <button className="py-4 px-6 text-white bg-[#001AFF] hover:bg-blue-700 rounded-[100px] w-full h-[50px] flex items-center justify-center text-[14px]">
                  <img src={EmailImg} alt="email" />
                  developers@nichetensor.com
                </button>
              </div>
            </Modal>
            <button
              className="py-4 px-6 text-white bg-black hover:bg-slate-700 rounded-[100px] w-full h-[50px] flex items-center justify-center"
              onClick={() => setOpenUpgradeModal(true)}
            >
              Upgrade
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="flex gap-4 mb-5">
          <button className="bg-white rounded-[100px] py-0 sm:py-3 px-[6px] sm:px-8 text-[14px] sm:text-[16px]">API Keys</button>
          <button className="text-[14px] sm:text-[16px]" onClick={() => handleAddAPIKey()}>Add API Keys</button>
        </div>
        <div className="w-full pb-4">
          {apiKeys.map((value) => {
            return (
            <div key={ value.id } className="flex justify-between items-center mt-2 w-full gap-2">
              <div className="text-[12px] sm:text-[14px]">{ value.name } API Key</div>
              <div className="flex items-center text-[12px] sm:text-[14px]">
                <p className="break-all hidden sm:block">{ handleMaskString(value.key, 12) }</p>
                <p className="break-all block sm:hidden">{ handleMaskString(value.key, 3) }</p>
                { value.isCopied ? <img src={CopySuccessImg} alt="copySuccess" className="w-[18px] h-[18px] ml-2" /> :
                <button onClick={() => handleAPIKeyCopy(value.id, value.key)}><img src={CopyImg} alt="copy" className="w-[18px] h-[18px] mr-2" /></button>}
              </div>
              <div className="flex items-center text-[10px] sm:text-[14px]">
                <p className="hidden sm:block">{ value.date }</p>
                <button id={value.id.toString()} onClick={(e) => handleDeleteAPIKey(e)}><img src={DeleteImg} alt="delete" className="w-[18px] h-[18px] ml-2" /></button>
              </div>
            </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default DashboardSection;
