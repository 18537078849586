import React from "react";

const BillingSection: React.FC = () => {
  return (
    <section className="p-8 rounded-[16px] md:rounded-[30px] bg-white mt-36 w-[460px]">
      <div className="flex">
        <p className="font-bold text-[42px]">Billing</p>
      </div>
      <div className="flex justify-center items-center w-full gap-2 mt-4 text-[11px] md:text-[14px]">
        <button className="w-1/2 h-[50px] bg-[#001AFF] hover:bg-blue-700 rounded-[100px] py-4 px-6 flex items-center justify-center text-white">Add to Balance</button>  
        <button className="w-1/2 h-[50px] bg-black hover:bg-slate-700 rounded-[100px] py-4 px-6 flex items-center justify-center text-white">Add Billing Method</button>  
      </div>
      <div className="w-full border border-solid border-[#F2F2F2] mt-4"></div>
      <div className="font-medium text-[14px] flex mt-4">
        <p>Saved Billing Methods</p>
      </div>
      <div className="font-normal text-[14px] flex justify-center text-[#00000080] mt-4">
        <p>No billing methods found.</p>
      </div>
      <div className="w-full border border-solid border-[#F2F2F2] mt-4"></div>
      <div className="font-medium text-[14px] flex mt-4">
        <p>Transactions</p>
      </div>
      <div className="font-normal text-[14px] flex justify-center text-[#00000080] mt-4">
        <p>No transactions found.</p>
      </div>
    </section>
  )
}

export default BillingSection;
