import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import ImageAPI from './pages/ImageAPI';
import DashBoard from './pages/Dashboard';
import { AppContextProvider } from './utils/AppContext';
import ModalContainer from './components/ModalContainer';

function App() {

  return (
    <BrowserRouter>
      <AppContextProvider>
        <Header />
        <main className='overflow-hidden lg:overflow-visible pt-5 md:pt-10 2xl:pt-20 min-h-screen'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/image-api' element={<ImageAPI />} />
            <Route path='/dashboard' element={<DashBoard />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </main>
        <Footer />
        <ModalContainer />
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
