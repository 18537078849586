import React from 'react';
import ImageHelmet from '../../assets/images/image-api-hero.png';
import ArrowIcon from '../icons/arrow';
import ArrowMobileIcon from '../icons/arrow-mobile';
import { useParallax } from 'react-scroll-parallax';
import ImageAPIFeatures from './ImageAPIFeatures';
import Credit from './Credit';

const ImageAPIHeroSection: React.FC = () => {
  const title = 'POWERFUL VISUAL CONTENT WITH NICHTENSOR'
  const description = 'A VARIETY OF MODELS, EACH WITH UNIQUE STYLES AND SPEEDS, FIND THE PERFECT FIT FOR YOUR NEEDS'
  const heroParallaxRef = useParallax<HTMLDivElement>({
  });
  const mArrowParallaxRef = useParallax<HTMLDivElement>({
  });
  const divParallaxRef = useParallax<HTMLDivElement>({
    onProgressChange: (progress) => {
      const translateHeadY = -progress * 30; // Adjust the multiplier as needed for the desired effect
      if (heroParallaxRef.ref.current && mArrowParallaxRef.ref.current) {
        if (window.innerWidth <= 768) { // Mobile view
          heroParallaxRef.ref.current.style.setProperty(
            "--hero-api-translateY",
            translateHeadY + "vh"
          ); // Different transform for mobile
          mArrowParallaxRef.ref.current.style.setProperty(
            "--hero-api-translateY",
            translateHeadY + "vh"
          );
        }
      }
    }
  });

  return (
    <section className='relative bg-gradient-to-b from-gray-500 to-white'>
      <div className="flex flex-col items-center h-[73vh] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 md:px-20 pt-15 md:pt-5 relative justify-between">
        <h1 className='whitespace-normal md:whitespace-pre-wrap uppercase mt-[5vh] text-[42px] 2xl:text-[92px] leading-[50px] 2xl:leading-[120px] pb-[20px] text-center mt-[100px] 2xl:mt-0'>{title}</h1>
        <div ref={heroParallaxRef.ref} className="api-hero-parallax absolute mt-[27vh] md:mt-[20vh] z-10">
          <img
            src={ImageHelmet}
            alt="Helmet"
            className='h-[60vh]'
          />
        </div>
        <p className='mix-blend-difference text-azure mt-[10vh] md:text-[20px] md:leading-[25.34px] md:w-[850px] text-center uppercase z-10 mt-[40px] relative text-shadow-md max-w-full' >{description}</p>
        <div className='flex justify-center relative z-20 mt-[18vh]'>
          <div className='hero-arrow-opacity'>
            <div className='arrow-icon'>
              <ArrowIcon />
            </div>
            <div className='arrow-mobile-icon' ref={mArrowParallaxRef.ref}>
              <ArrowMobileIcon />
            </div>
          </div>
        </div>
      </div>
      <ImageAPIFeatures />
      <Credit />
      <div className="absolute m-api-empty">
        <div className="m-api-full"></div>
        <div className="empty" ref={divParallaxRef.ref as React.RefObject<HTMLDivElement>}></div>
      </div>
    </section >
  );
};

export default ImageAPIHeroSection;