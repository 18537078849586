import React, { useState } from "react";
import RefreshIcon from "../../assets/images/refresh.svg";

const LogsSection: React.FC = () => {
  const [rotation, setRotation] = useState(0);

  const handleClick = () => {
    setRotation(prevRotation => prevRotation + 360);
  };
  
  return (
    <section className="p-8 rounded-[16px] md:rounded-[30px] bg-white mt-36 w-[460px]">
      <div className="flex justify-between items-center">
        <p className="font-bold text-[42px]">Logs</p>
        <button onClick={handleClick}>
          <img
            src={RefreshIcon}
            alt="refresh"
            className="w-[40px] h-[40px] transition-transform duration-1000 ease-in-out"
            style={{ transform: `rotate(${rotation}deg)` }}
          />
        </button>
      </div>
      <div className="font-medium text-[22px] flex mt-6">
        <p>No Logs found.</p>
      </div>
      <div className="font-normal text-[14px] flex text-[#00000080] mt-4">
        <p className="text-start">Please head over to the API dashboard to generate API keys and get started viewing your logs.</p>
      </div>
      <div className="mt-6">
        <button className="w-full h-[50px] bg-[#001AFF] hover:bg-blue-700 rounded-[100px] py-4 px-6 flex items-center justify-center text-white">Create API Key</button>
      </div>
    </section>
  )
}

export default LogsSection;
