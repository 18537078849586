import React from 'react';
import Feat1 from '../../assets/images/feat1.png';
import Feat2 from '../../assets/images/feat2.png';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

const MainSection: React.FC = () => {
  const textGenFeatures = [
    'Fast Generation Time',
    'Pricing From 1 USD per Million Tokens',
    'Cutting Edge Models',
  ];
  const imgGenTitle = `EXPLORE A DIVERSE
RANGE OF MODELS`
  const imgGenDesc = `Each offering unique styles and generation speeds. Whether you're looking for creativity, efficiency, or a specific artistic touch, you'll find the perfect model tailored to your requirements.`
  const textGenTitle = `Intelligent Text
Generation`
  const textGenDesc = `At an unbeatable value. Enhance your applications with top-tier text models, offering a range of capabilities at an exceptional price.`
  return (
    <ParallaxBanner>
      <section className="bg-gray-500 md:pt-20" id='main'>
        <ParallaxBannerLayer image="/bk.png" speed={-20} />
        <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 md:px-10 2xl:px-20 relative'>
          <div className='bg-white rounded-[16px] md:rounded-[30px] p-[20px] md:p-[30px] 2xl:p-[60px] flex justify-between md:pb-[38px] flex-col xl:flex-row gap-[40px]'>
            <div className='w-full 2xl:w-[550px]'>
              <span className='bg-black rounded-[100px] text-white py-3 md:py-4 px-4 md:px-6 uppercase inline-block text-[12px] md:text-[16px]'>Image Generation</span>
              <h3 className='mt-5 md:mt-10 mb-6 uppercase text-[32px] md:text-[50px] whitespace-pre-wrap leading-[32px] md:leading-[50px]'>{imgGenTitle}</h3>
              <p className='text-[14px] md:text-[20px] opacity-70 font-inter leading-[16.94px] md:leading-[24.2px] mb-5 md:mb-10 md:h-[100px]'>{imgGenDesc}</p>
              <div className='flex justify-between flex-col lg:flex-row gap-[20px]'>
                <img src={Feat1} alt="Feature 1" className='rounded-[20px] w-full lg:w-[420px] xl:w-[260px] h-[146.5px] md:h-[350px] xl:h-[273px] object-cover' />
                <img src={Feat2} alt="Feature 2" className='rounded-[20px] w-full lg:w-[420px] xl:w-[260px] h-[146.5px] md:h-[350px] xl:h-[273px] object-cover' />
              </div>
            </div>
            <div className='w-full 2xl:w-[550px]'>
              <span className='bg-black rounded-[100px] text-white py-3 md:py-4 px-4 md:px-6 uppercase inline-block text-[12px] md:text-[16px]'>Text Generation</span>
              <h3 className='mt-5 md:mt-10 mb-6 uppercase text-[32px] md:text-[50px] whitespace-pre-wrap leading-[32px] md:leading-[50px]'>{textGenTitle}</h3>
              <p className='text-[14px] md:text-[20px] opacity-70 font-inter leading-[16.94px] md:leading-[24.2px] mb-5 md:mb-10 md:h-[100px]'>{textGenDesc}</p>
              <div className='bg-black rounded-[20px] w-full p-[20px] md:p-[30px] md:h-[273px] flex flex-col justify-between gap-[20px]'>
                {textGenFeatures.map((feature, idx) => (
                  <div key={idx} className='flex items-center font-inter text-[20px] leading-[24.2px]'>
                    <span className='bg-blue-500 rounded-[100px] w-[40px] md:w-[50px] h-[40px] md:h-[50px] text-white mr-[20px] flex items-center justify-center shrink-0'>{idx + 1}</span>
                    <p className='text-white text-[14px] md:text-[20px] leading-[16.94px] md:leading-[24.2px]'>{feature}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </ParallaxBanner>
  );
};

export default MainSection;