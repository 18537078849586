import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface IUserInfo {
  email: string;
  auth_key: string;
  credit: number; // number of credits
  request_count: number; // number of requests made
}
interface IAppContextType {
  isSignInModalOpen: boolean;
  setIsSignInModalOpen: (isOpen: boolean) => void;
  isSignUpModalOpen: boolean;
  setIsSignUpModalOpen: (isOpen: boolean) => void;
  isProfileModalOpen: boolean;
  setIsProfileModalOpen: (isOpen: boolean) => void;
  signOut: () => void;
  userInfo: IUserInfo;
  setUserInfo: (userInfo: IUserInfo) => void;
}

const AppContext = createContext<IAppContextType | undefined>(undefined);

interface IAppContextProviderProps {
  children: ReactNode;
}

export const AppContextProvider: React.FC<IAppContextProviderProps> = ({ children }) => {
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [userInfo, updateUserInfo] = useState(() => {
    const userInfo = localStorage.getItem('userInfo');
    return userInfo ? JSON.parse(userInfo) : { email: '', auth_key: '', credit: 0 };
  });
  const setUserInfo = (userInfo: IUserInfo) => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    updateUserInfo(userInfo);
  }
  const signOut = () => {
    localStorage.removeItem('userInfo');
    updateUserInfo({ email: '', auth_key: '', credit: 0 });
  }

  return (
    <AppContext.Provider value={{ isSignInModalOpen, setIsSignInModalOpen, isSignUpModalOpen, setIsSignUpModalOpen, userInfo, setUserInfo, isProfileModalOpen, setIsProfileModalOpen, signOut }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the AppContext
export const useAppContext = (): IAppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};