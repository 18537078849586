import React from 'react';

interface IAvatarProps {
  userName: string;
  imageUrl?: string;
}

const Avatar: React.FC<IAvatarProps> = ({ imageUrl, userName }) => {

  return imageUrl ? (
    <img src={imageUrl} alt={"Avatar"} className='rounded-full'/>
  ) : (
    <div className='flex justify-center items-center w-10 h-10 bg-blue-500 text-white text-2xl font-extrabold rounded-full'>
      {userName.charAt(0).toUpperCase()}
    </div>
  )
};

export default Avatar;